import React from "react";
import { AirQualityData } from "../Pages/DtlsPages";

type propsType = {
  latestData: AirQualityData[];
};
export const AllPollutant = ({ latestData }: propsType) => {
  return (
    <div className="col-span-2 overflow-y-scroll  bg-gray-50 rounded-2xl h-[16rem] mb-4   border-2 border-gray-300">
      <table className="min-w-full text-left text-sm font-light overflow-scroll">
        <thead className="border-b font-medium dark:border-neutral-500">
          <tr>
            <th scope="col" className="px-6 py-4">
              Pollutants
            </th>
            <th scope="col" className="px-6 py-4">
              Units
            </th>
            <th scope="col" className="px-6 py-4">
              Avg
            </th>
            <th scope="col" className="px-6 py-4">
              Min
            </th>
            <th scope="col" className="px-6 py-4">
              Max
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">PM2.5</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.PM25_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.PM25_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.PM25_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">PM10</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.PM10_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.PM10_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.PM10_MAX).toFixed(2)}
            </td>
          </tr>
          {/* 
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">PM2.5</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.PM25_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.PM25_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.PM25_MAX).toFixed(2)}
            </td>
          </tr> */}
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">NO</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NO_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NO_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NO_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">NO2</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NO2_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NO2_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NO2_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">NOx</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NOX_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NOX_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NOX_MAX).toFixed(2)}
            </td>
          </tr>

          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">NH3</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NH3_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NH3_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.NH3_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">SO2</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.SO2_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.SO2_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.SO2_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">CO</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              mg/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.CO_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.CO_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.CO_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">Ozone</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.O3_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.O3_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.O3_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">Benzene</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.BENZENE_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.BENZENE_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.BENZENE_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">
              Ethyl Benzene
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.ETHYL_BENZENE_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.ETHYL_BENZENE_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.ETHYL_BENZENE_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">Toluene</td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.TOLUENE_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.TOLUENE_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.TOLUENE_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">
              MP Xylene
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.MP_XYLENE_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.MP_XYLENE_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.MP_XYLENE_MAX).toFixed(2)}
            </td>
          </tr>
          <tr className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4 font-medium">
              O Xylene
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              ug/m3
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.O_XYLENE_AVG).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.O_XYLENE_MIN).toFixed(2)}
            </td>
            <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
              {parseFloat(latestData[0]?.O_XYLENE_MAX).toFixed(2)}
            </td>
          </tr>

          {/* {Sheet1?.map((pollutant) => (
            <tr className="border-b dark:border-neutral-500">
              <td className="whitespace-nowrap px-6 py-4 font-medium">
                {pollutant?.polutent}
              </td>
              <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
                {pollutant?.units}
              </td>
              <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
                NA
              </td>
              <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
                NA
              </td>
              <td className="whitespace-nowrap px-6 py-4 font-sans font-semibold">
                NA
              </td>
            </tr>
          ))} */}
        </tbody>
      </table>
    </div>
  );
};
