import { Dispatch, SetStateAction, useState } from "react";
import WbpcbLogo from "../assets/images/wbpcb-logo.svg";
import { useNavigate, useLocation } from "react-router-dom";

function Header() {
  const nav = useNavigate();
  const location = useLocation();
  const [dataTypeTab, setDataTypeTab] = useState("today");

  return (
    <header className="bg-[#0D8f87] w-full  px-2 sm:px-10 py-2 grid grid-cols-1 sm:grid-cols-2 items-center">
      <p className=" text-white font-sans font-semibold sm:text-xl ">
        West Bengal Air Quality Index
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2  gap-2 items-center">
        <div className="flex items-center sm:justify-end">
          <img
            src={WbpcbLogo}
            alt="WBPCb Logo"
            className="w-12 h-12 object-contain"
          />
          <p className=" text-white font-sans font-semibold sm:text-xl ">
            West Bengal Pollution Control Board
          </p>
        </div>
        {setDataTypeTab && (
          <div className="grid grid-cols-2 gap-2 sm:ml-6 ">
            <div
              className={`px-4 py-1 border rounded-lg ${
                location.pathname === "/" && "bg-slate-600"
              } duration-300 hover:bg-slate-500 cursor-pointer`}
              onClick={() => {
                setDataTypeTab("today");
                nav("/");
              }}
            >
              <p className="font-semibold font-sans text-base text-white">
                Today
              </p>
            </div>
            <div
              className={`px-4 py-1 border rounded-lg ${
                location.pathname === "/history" && "bg-slate-600"
              } duration-300 hover:bg-slate-500 cursor-pointer`}
              onClick={() => {
                setDataTypeTab("historic");
                nav("/history");
              }}
            >
              <p className="font-semibold font-sans text-base text-white ">
                Historic
              </p>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
