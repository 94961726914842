import React from "react";

function AQILabelCard() {
  return (
    <div className=" bg-gray-50 rounded-2xl h-[16rem] py-0 px-0 mb-0 ml-0 sm:py-1 sm:px-2 sm:mb-4 sm:ml-4 border-2 border-gray-300 overflow-x-scroll sm:overflow-hidden">
      <table className="min-w-full text-left text-sm font-light">
        <thead className="font-medium">
          <tr>
            <th scope="col" className=" font-bold font-sans px-5 py-2">
              AQI
            </th>
            <th scope="col" className="font-bold font-sans px-2 py-2">
              COLOR CODE
            </th>
            <th scope="col" className=" font-bold font-sans px-2 py-2">
              REMARKS
            </th>
            <th scope="col" className="font-bold font-sans px-2 py-2">
              POSSIBLE HEALTH IMPACTS
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="whitespace-nowrap px-5 py-2 font-bold flex items-center gap-1">
              <p className="text-sm text-[#686868]  font-semibold font-sans ">
                0-50
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2 font-semibold font-sans">
              <div className="bg-[#00B050] w-10 h-5 rounded-full" />
            </td>

            <td className="whitespace-nowrap px-2 py-2 font-semibold font-sans">
              <p className="text-sm text-[#686868]  font-semibold font-sans pr-4">
                Good
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2 font-semibold font-sans">
              <p className="text-sm text-[#686868] font-semibold font-sans pr-4">
                Minimal impact
              </p>
            </td>
          </tr>
          <tr>
            <td className="whitespace-nowrap px-5 py-2  font-bold flex items-center gap-1">
              <p className="text-sm text-[#686868]  font-semibold font-sans ">
                51-100
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2  font-semibold font-sans">
              <div className="bg-[#92D050] w-10 h-5 rounded-full" />
            </td>
            <td className="whitespace-nowrap px-2 py-2  font-semibold font-sans">
              <p className="text-sm text-[#686868]  font-semibold font-sans pr-4">
                Satisfactory
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2  font-semibold font-sans">
              <p className="text-sm text-[#686868] font-semibold font-sans pr-4">
                Minor breathing discomfort to sensitive people
              </p>
            </td>
          </tr>
          <tr>
            <td className="whitespace-nowrap px-5 py-2  font-bold flex items-center gap-1">
              <p className="text-sm text-[#686868]  font-semibold font-sans ">
                101-200
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2 font-semibold font-sans">
              <div className="bg-[#ffff00] w-10 h-5 rounded-full" />
            </td>
            <td className="whitespace-nowrap px-2 py-2 font-semibold font-sans">
              <p className="text-sm text-[#686868]  font-semibold font-sans pr-4">
                Moderate
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2 font-semibold font-sans">
              <p className="text-sm text-[#686868] font-semibold font-sans pr-4">
                Breathing discomfort to the people with lungs, asthma and heart
                diseases
              </p>
            </td>
          </tr>
          <tr>
            <td className="whitespace-nowrap px-5  py-2 font-bold flex items-center gap-1">
              <p className="text-sm text-[#686868]  font-semibold font-sans ">
                201-300
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2  font-semibold font-sans">
              <div className="bg-[#ff9900] w-10 h-5 rounded-full" />
            </td>
            <td className="whitespace-nowrap px-2  py-2 font-semibold font-sans">
              <p className="text-sm text-[#686868]  font-semibold font-sans pr-4">
                Poor
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2 font-semibold font-sans">
              <p className="text-sm text-[#686868] font-semibold font-sans pr-4">
                Breathing discomfort to the people with lungs, asthma and heart
                diseases
              </p>
            </td>
          </tr>
          <tr>
            <td className="whitespace-nowrap px-5 py-2  font-bold flex items-center gap-1">
              <p className="text-sm text-[#686868]  font-semibold font-sans ">
                301-400
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2 font-semibold font-sans">
              <div className="bg-[#ff0000] w-10 h-5 rounded-full" />
            </td>
            <td className="whitespace-nowrap px-2 py-2  font-semibold font-sans">
              <p className="text-sm text-[#686868]  font-semibold font-sans pr-4">
                Very Poor
              </p>
            </td>
            <td className="whitespace-nowrap px-2 py-2 font-semibold font-sans">
              <p className="text-sm text-[#686868] font-semibold font-sans pr-4">
                Respiratory illness on prolonged exposure
              </p>
            </td>
          </tr>
          <tr>
            <td className="whitespace-nowrap px-5 font-bold flex items-center gap-1">
              <p className="text-sm text-[#686868]  font-semibold font-sans ">
                401-500
              </p>
            </td>
            <td className="whitespace-nowrap px-2 font-semibold font-sans">
              <div className="bg-[#c00000] w-10 h-5 rounded-full" />
            </td>
            <td className="whitespace-nowrap px-2 font-semibold font-sans">
              <p className="text-sm text-[#686868]  font-semibold font-sans pr-4">
                Severe
              </p>
            </td>
            <td className="whitespace-nowrap px-2 font-semibold font-sans">
              <p className=" text-sm text-[#686868] font-semibold font-sans pr-4">
                Affects healthy people and seriously impacts those with existing
                diseases
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AQILabelCard;
