import React from "react";
import { AirQualityData } from "../Pages/DtlsPages";

type propsType = {
  latestData: AirQualityData[];
};

function BasicPollutant({ latestData }: propsType) {
  const aqiColorCodeString = (aqiValue: number) => {
    if (aqiValue <= 50 && aqiValue > 0) return "#00B050";
    else if (aqiValue > 50 && aqiValue <= 100) return "#92D050";
    else if (aqiValue > 100 && aqiValue <= 200) return "#FFFF00";
    else if (aqiValue > 200 && aqiValue <= 300) return "#FF9900";
    else if (aqiValue > 300 && aqiValue <= 400) return "#FF0000";
    else if (aqiValue > 500) return "#C00000";
    else return "#2bcd11";
  };

  return (
    <div className="col-span-1 bg-gray-50 py-3   rounded-2xl  my-4 border-2 border-gray-300">
      <table className="min-w-full text-left text-sm font-light">
        <thead className="font-medium">
          <tr>
            <th scope="col" className="px-4 pr-6 py-3">
              Pollutants
            </th>
            <th scope="col" className="px-2 py-3">
              Avg
            </th>
            <th scope="col" className="px-2 py-3">
              Min
            </th>
            <th scope="col" className="px-2 py-3">
              Max
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="">
            <td className="whitespace-nowrap px-5 py-[15px] font-bold flex items-center gap-1">
              <div
                className="w-3 h-3 rounded-full"
                style={{
                  backgroundColor: aqiColorCodeString(
                    latestData[0]?.PM25_AVG_SI
                  ),
                }}
              />
              PM2.5
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.PM25_AVG_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.PM25_MIN_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.PM25_MAX_SI}
            </td>
          </tr>
          <tr className="">
            <td className="whitespace-nowrap px-5 py-[15px] font-bold flex items-center gap-1">
              <div
                className="w-3 h-3 rounded-full "
                style={{
                  backgroundColor: aqiColorCodeString(
                    latestData[0]?.PM10_AVG_SI
                  ),
                }}
              />
              PM10
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.PM10_AVG_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.PM10_MIN_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.PM10_MAX_SI}
            </td>
          </tr>
          <tr className="">
            <td className="whitespace-nowrap px-5 py-[15px] font-bold flex items-center gap-1">
              <div
                className="w-3 h-3 rounded-full "
                style={{
                  backgroundColor: aqiColorCodeString(
                    latestData[0]?.NO2_AVG_SI
                  ),
                }}
              />
              NO2
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.NO2_AVG_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.NO2_MIN_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.NO2_MAX_SI}
            </td>
          </tr>
          <tr className="">
            <td className="whitespace-nowrap px-5 py-[15px] font-bold flex items-center gap-1">
              <div
                className="w-3 h-3 rounded-full"
                style={{
                  backgroundColor: aqiColorCodeString(
                    latestData[0]?.NH3_AVG_SI
                  ),
                }}
              />
              NH3
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.NH3_AVG_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.NH3_MIN_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.NH3_MAX_SI}
            </td>
          </tr>
          <tr className="">
            <td className="whitespace-nowrap px-5 py-[15px] font-bold flex items-center gap-1">
              <div
                className="w-3 h-3 rounded-full "
                style={{
                  backgroundColor: aqiColorCodeString(
                    latestData[0]?.SO2_AVG_SI
                  ),
                }}
              />
              SO2
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.SO2_AVG_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.SO2_MIN_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.SO2_MAX_SI}
            </td>
          </tr>
          <tr className="">
            <td className="whitespace-nowrap px-5 py-[15px] font-bold flex items-center gap-1">
              <div
                className="w-3 h-3 rounded-full "
                style={{
                  backgroundColor: aqiColorCodeString(latestData[0]?.CO_AVG_SI),
                }}
              />
              CO
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.CO_AVG_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.CO_MIN_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.CO_MAX_SI}
            </td>
          </tr>
          <tr className="">
            <td className="whitespace-nowrap px-5 py-[15px] font-bold flex items-center gap-1">
              <div
                className="w-3 h-3 rounded-full "
                style={{
                  backgroundColor: aqiColorCodeString(latestData[0]?.O3_AVG_SI),
                }}
              />
              OZONE
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.O3_AVG_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.O3_MIN_SI}
            </td>
            <td className="whitespace-nowrap px-2 py-[15px] font-semibold font-sans">
              {latestData[0]?.O3_MAX_SI}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BasicPollutant;
