import { realtimeHumidityTemperatureDateType } from "../Pages/DtlsPages";
import AcMain from "../assets/images/AcMain.svg";
import AcUps from "../assets/images/AcUps.svg";
import UpsBackup from "../assets/images/UpsBackup.svg";
import UpsMain from "../assets/images/UpsMain.svg";
import { IStnData } from "./Map";

type propsType = {
  stnData: any;
  realtimeHumidityTemperature: realtimeHumidityTemperatureDateType | null;
};

const InfoCard = ({ stnData, realtimeHumidityTemperature }: propsType) => {
  const data = [
    {
      id: 1,
      icon: AcMain,
      color: "#FFC480",
      name: "AC - Main",
      status: false,
    },
    {
      id: 2,
      icon: AcUps,
      color: "#FFF159",
      name: "AC - UPS",
      status: true,
    },
    {
      id: 3,
      icon: UpsMain,
      color: "#54A8FF",
      name: "UPS - Main",
      status: true,
    },
    {
      id: 4,
      icon: UpsBackup,
      color: "#B394FF",
      name: "UPS - Backup",
      status: false,
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 sm:col-span-2 bg-gray-50 border-2 sm:overflow-hidden rounded-2xl sm:h-[16rem] border-gray-300 mt-2">
      <div className="col-span-2 border-r-2 border-gray-300 px-8 py-1">
        <div className="flex items-center gap-2 pb-[3px]">
          <p className="text-base font-sans"> Station : </p>
          <p className="text-black font-bold text-base font-sans ">
            {stnData?.location}
          </p>
        </div>
        <div className="flex items-center gap-2 pb-[3px]">
          <p className="text-base font-sans">Prominent Pollutant :</p>
          <p className="text-black font-bold text-base font-sans">
            {stnData?.prominent_pollutent}
          </p>
        </div>
        <div className="flex items-center gap-2 pb-[3px]">
          <p className="text-base font-sans">Date/Time :</p>
          <p className="text-black font-bold text-base font-sans">
            {stnData?.date} at {stnData?.hour} hour
          </p>
        </div>
        <div className="flex items-center gap-2 pb-[3px]">
          <p className="text-base font-sans">Relative Humidity :</p>
          <p className="text-black font-bold text-base font-sans">
            {/* {stnData.humidity}% */}
            {realtimeHumidityTemperature?.humidity || "--"} %
          </p>
        </div>
        <div className="flex items-center gap-2 pb-[3px]">
          <p className="text-base font-sans">Relative Temperature :</p>
          <p className="text-black font-bold text-base font-sans">
            {/* {stnData.temperature} degree C */}
            {realtimeHumidityTemperature?.temperature || "--"} degree C
          </p>
        </div>
        {/*  */}
        <div className="flex items-center gap-2 pb-[3px]">
          <p className="text-base font-sans">Wind Speed :</p>
          <p className="text-black font-bold text-base font-sans">
            {realtimeHumidityTemperature?.wind_speed_mps !== null
              ? realtimeHumidityTemperature?.wind_speed_mps
              : "--"}{" "}
            m/s
          </p>
        </div>
        <div className="flex items-center gap-2 pb-[3px]">
          <p className="text-base font-sans">Wind Direction :</p>
          <p className="text-black font-bold text-base font-sans">
            {realtimeHumidityTemperature?.wind_direction !== null
              ? realtimeHumidityTemperature?.wind_direction
              : "--"}{" "}
          </p>
        </div>
        <div className="flex items-center gap-2 pb-[3px]">
          <p className="text-base font-sans">Rainfall Intensity :</p>
          <p className="text-black font-bold text-base font-sans">
            {realtimeHumidityTemperature?.rainfall_intensity !== null
              ? realtimeHumidityTemperature?.rainfall_intensity
              : "--"}{" "}
            mm
          </p>
        </div>
        <div className="flex items-center gap-2 pb-[3px]">
          <p className="text-base font-sans">Solar Radiation:</p>
          <p className="text-black font-bold text-base font-sans">
            {realtimeHumidityTemperature?.solar_radiation !== null
              ? realtimeHumidityTemperature?.solar_radiation
              : "--"}{" "}
            W/m2
          </p>
        </div>
      </div>

      <div className="col-span-1 grid grid-cols-2 sm:grid-cols-1  px-4 py-4">
        {data.map((k) => (
          <div key={k.id} className="flex items-center gap-4 mb-2">
            <div
              className={`relative rounded-full items-center p-2`}
              style={{ backgroundColor: k.color }}
            >
              <img src={k.icon} alt="Logo" className="w-8 h-8 object-contain" />
              <div
                className="rounded-full w-3 h-3 absolute -right-1 bottom-2 z-10"
                style={{ backgroundColor: k.status ? "green" : "red" }}
              />
            </div>
            <p className="font-sans font-semibold">{k.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoCard;
