import { registerLicense } from "@syncfusion/ej2-base";
import { Route, Routes } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DtlsPages from "./Pages/DtlsPages";
import HomePage from "./Pages/HomePage";
import HistoryPage from "./Pages/HistoryPage";

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NHaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9ednRSR2VYUkJzXkI="
);

const App = () => {
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<HomePage />} />
        <Route path="/today" element={<DtlsPages />} /> */}
        <Route path="/" element={<DtlsPages />} />
        <Route path="/history" element={<HistoryPage />} />
      </Routes>
    </div>
  );
};
export default App;
