import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import "./Chart.css";
import { GraphDataType } from "../../Typing";
import moment from "moment";

const currentDate = moment();
const pastDateTime = currentDate.subtract(23, "hours");
const start_time = moment().add(1, "hour");

function ChatGraph({
  selectedStation,
}: {
  selectedStation: { id: number } | any;
}) {
  const [chartWidth, setChartWidth] = useState(400); // Initial width
  const chartRef: any = useRef(null);

  const [data, setData] = useState<GraphDataType[] | null>();
  var counter = false;

  const [graphData, setGraphData] = useState<{
    pm25: (number | null)[];
    pm10: (number | null)[];
    no2: (number | null)[];
    nh3: (number | null)[];
    so2: (number | null)[];
    co: (number | null)[];
    ozone: (number | null)[];
  }>({
    pm25: [],
    pm10: [],
    no2: [],
    nh3: [],
    so2: [],
    co: [],
    ozone: [],
  });
  const [dataLabel, setDataLabel] = useState<string[]>([]);
  var DUMMY_DATA_ARR: Number[] = [];

  // Function to update chart width
  const updateChartWidth = () => {
    const newWidth = chartRef.current.offsetWidth;
    setChartWidth(newWidth);
  };
  const gerDataFromAPi = async () => {
    fetch(
      `https://caaqms.wbpcb.wtlprojects.com:9100/v1.0/sens/real/get_hour_avg?start_time=${moment(
        pastDateTime
      ).format("YYYY-MM-DD HH:mm:ss")}&end_time=${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}&station_id=${selectedStation?.id}`
    )
      .then((response) => response.json())
      .then((res) => {
        setData(res?.data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    gerDataFromAPi();
  }, [selectedStation]);

  useEffect(() => {
    window.addEventListener("resize", updateChartWidth);
    updateChartWidth(); // Initial chart width

    return () => {
      window.removeEventListener("resize", updateChartWidth);
    };
  }, []);

  useEffect(() => {
    setDataLabel([]);
    setGraphData({
      pm25: [],
      pm10: [],
      no2: [],
      nh3: [],
      so2: [],
      co: [],
      ozone: [],
    });
    if (data) {
      data?.map((item: GraphDataType) => {
        setDataLabel((prev) => [
          ...prev,
          moment(`${item.date} ${item.hour}`, "YYYY-MM-DD HH").format(
            "hA, Do MMM YY"
          ),
        ]);
        setGraphData((prev) => ({
          pm25: [...prev.pm25, Math.round(Number(item?.pm25_si))],
          pm10: [...prev.pm10, Math.round(Number(item.pm10_si))],
          no2: [...prev.no2, Math.round(Number(item.no2_si))],
          nh3: [...prev.nh3, Math.round(Number(item.nh3_si))],
          so2: [...prev.so2, Math.round(Number(item.so2_si))],
          co: [...prev.co, Math.round(Number(item.co_si))],
          ozone: [...prev.ozone, Math.round(Number(item.o3_si))],
        }));
      });
    }
  }, [data]);
  const options: ApexCharts.ApexOptions = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // Customize the tooltip content
        return (
          '<div style="text-align: center;">' +
          '<span style="font-weight: bold;">' +
          w.config.series[seriesIndex].name +
          " " +
          '<span style="font-weight: normal;">' +
          series[seriesIndex][dataPointIndex] +
          "<span>" +
          "</span><br>" +
          '<span style="font-size: 14px">' +
          w.config.xaxis.categories[dataPointIndex] +
          "</span><br>" +
          "</div>"
        );
      },
    },
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      height: 10,
    },
    series: [
      {
        name: "Example Series",
        data: [
          44, 55, 41, 64, 22, 43, 21, 44, 55, 41, 64, 22, 43, 21, 44, 55, 41,
          64, 22, 43, 21, 44, 55, 41,
        ],
      },
    ],
    xaxis: {
      categories: dataLabel,
      labels: {
        show: false,
        // Hide x-axis labels
        style: {
          fontSize: "0px",
        },
      },
      axisBorder: {
        color: "black",
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false, // Hide y-axis labels
      },
    },
    dataLabels: {
      enabled: false, // This line hides the labels from the bars
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: chartWidth * 0.005,
        borderRadiusApplication: "end",
        colors: {
          // Define custom colors for each bar
          ranges: [
            {
              from: 0,
              to: 50,
              color: "#00B050", // Color for bars with values from 0 to 44
            },
            {
              from: 51,
              to: 100,
              color: "#92D050", // Color for bars with values from 45 to 64
            },
            {
              from: 101,
              to: 200,
              color: "#FFFF00", // Color for bars with values from 65 to 100
            },
            {
              from: 201,
              to: 300,
              color: "#FF9900", // Color for bars with values from 65 to 100
            },
            {
              from: 301,
              to: 400,
              color: "#FF0000", // Color for bars with values from 65 to 100
            },
            {
              from: 401,
              to: 100000000000,
              color: "#C00000", // Color for bars with values from 65 to 100
            },
          ],
        },
      },
    },
    grid: {
      show: false, // Hide both x and y-axis grid lines
    },
  };

  const Series = [
    {
      name: "PM2.5",
      data: [
        44, 55, 41, 64, 22, 43, 21, 44, 55, 41, 64, 22, 43, 21, 44, 55, 41, 64,
        22, 43, 21, 44, 55, 41,
      ],
    },
  ];
  return (
    <div className="col-span-2 bg-gray-50 rounded-2xl  sm:mx-4  my-4 relative">
      <p className="font-sans font-bold px-8 py-2 ">
        Air Quality Graph (Sub-Index)
      </p>
      <div className=" absolute left-8 mt-1">
        {["PM2.5", "PM10", "NO2", "NH3", "SO2", "CO", "Ozone"].map((item) => (
          <h1 className="py-[15px] text-black font-bold">{item}</h1>
        ))}
      </div>
      {/* <div className=" absolute  bottom-0  flex flex-row left-8 mb-1 ">
        {[
          "2PM, Wed",
          "6PM, Wed",
          "10PM, Wed",
          "2AM, Thu",
          "6AM, Thu",
          "10AM",
        ].map((item) => (
          <h1 className="px-10 text-black  rotate-0">{item}</h1>
        ))}
      </div> */}
      <div className="ml-20 mt-4">
        <div ref={chartRef} className="-mt-8 -mb-4 -z-10">
          <Chart
            options={options}
            series={[
              {
                name: "PM2.5:",
                data: graphData?.pm25,
              },
            ]}
            type="bar"
            height={70}
          />
        </div>
        <div ref={chartRef} className="-mt-8 -mb-4 -z-10">
          <Chart
            options={options}
            series={[
              {
                name: "PM10:",
                data: graphData?.pm10,
              },
            ]}
            type="bar"
            height={70}
          />
        </div>
        <div ref={chartRef} className="-mt-8 -mb-4 -z-10">
          <Chart
            options={options}
            series={[
              {
                name: "NO2:",
                data: graphData?.no2,
              },
            ]}
            type="bar"
            height={70}
          />
        </div>
        <div ref={chartRef} className="-mt-8 -mb-4 -z-10">
          <Chart
            options={options}
            series={[
              {
                name: "NH3:",
                data: graphData?.nh3,
              },
            ]}
            type="bar"
            height={70}
          />
        </div>
        <div ref={chartRef} className="-mt-8 -mb-4 -z-10">
          <Chart
            options={options}
            series={[
              {
                name: "SO2:",
                data: graphData?.so2,
              },
            ]}
            type="bar"
            height={70}
          />
        </div>
        <div ref={chartRef} className="-mt-8 -mb-4 -z-10">
          <Chart
            options={options}
            series={[
              {
                name: "CO:",
                data: graphData?.co,
              },
            ]}
            type="bar"
            height={70}
          />
        </div>
        <div ref={chartRef} className="-mt-8 -mb-4 -z-10">
          <Chart
            options={options}
            series={[
              {
                name: "O3:",
                data: graphData?.ozone,
              },
            ]}
            type="bar"
            height={70}
          />
        </div>
      </div>
    </div>
  );
}

export default ChatGraph;
