import React from "react";
import Chart from "react-apexcharts";

type propsType = {
  aqi: number;
};

const aqiCodeString = (aqiValue: number) => {
  if (aqiValue <= 50 && aqiValue > 0) return "Good";
  else if (aqiValue > 50 && aqiValue <= 100) return "Satisfactory";
  else if (aqiValue > 100 && aqiValue <= 200) return "Moderate";
  else if (aqiValue > 200 && aqiValue <= 300) return "Poor";
  else if (aqiValue > 300 && aqiValue <= 400) return "Very Poor";
  else if (aqiValue > 500) return "Severe";
  else return "N/A";
};
const aqiColorCodeString = (aqiValue: number) => {
  if (aqiValue <= 50 && aqiValue > 0) return "#00B050";
  else if (aqiValue > 50 && aqiValue <= 100) return "#92D050";
  else if (aqiValue > 100 && aqiValue <= 200) return "#FFFF00";
  else if (aqiValue > 200 && aqiValue <= 300) return "#FF9900";
  else if (aqiValue > 300 && aqiValue <= 400) return "#FF0000";
  else if (aqiValue > 500) return "#C00000";
  else return "#2bcd11";
};

function AQIGauge({ aqi }: propsType) {
  return (
    <div className="col-span-1 h-[16rem] bg-gray-50 rounded-2xl  sm:mx-4  mt-2  border-2 border-gray-300">
      <p className="font-semibold font-sans text-center text-[#909090] text-2xl mt-8 ">
        {aqiCodeString(aqi)}
      </p>
      <Chart
        type="radialBar"
        // className=""

        options={{
          colors: [aqiColorCodeString(aqi)],
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#d9d9d9",
                startAngle: -90,
                endAngle: 90,
              },
              dataLabels: {
                value: {
                  fontSize: "16px",
                  fontWeight: "bold",
                  show: true,
                  offsetY: -40,
                  formatter: function (val) {
                    return String((500 * (val / 100)).toFixed(0));
                  },
                },
                name: {
                  show: true,
                  color: "#000000",
                },
              },
            },
          },
          fill: {
            type: "solid",
          },
          stroke: {
            lineCap: "butt",
          },
          labels: ["AQI"],
        }}
        series={[aqi / 5]}
        // width={300}
        height={320}
      />
    </div>
  );
}

export default AQIGauge;
